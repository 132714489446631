import * as styles from '../styles/contacts.module.scss';

import React, { useRef, useState } from 'react';

import EmailIcon from '-!svg-react-loader!../assets/email-dark.svg';
import GoogleMap from '../components/GoogleMap';
import Layout from '../components/Layout';
import LocationIcon from '-!svg-react-loader!../assets/location-dark.svg';
import PhoneIcon from '-!svg-react-loader!../assets/phone-dark.svg';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';
import { SocialIcon } from 'react-social-icons';

const Contacts = () => {
  const [selectedLocation, setSelectedLocation] = useState('gerole');
  const form = useRef();

  const phoneNumber = '+39 3200318685';
  const email = 'carroroxana@gmail.com';
  const address = 'Via Gerole 29, Stezzano (BG)';

  const sendEmail = async (e) => {
    e.preventDefault();

    return window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber.replace(' ', '')}&text=${form.current['message'].value}`
    );
  };

  return (
    <Layout>
      <Seo title={'Pilart - Contattaci!'} description={'Scopri dove trovarci e molto altro!'} />
      <SectionTitle title="CONTATTI" />
      <section className={styles.contactSection}>
        <div>
          <LocationIcon className={styles.contactsIcon} />
          <p>LA NOSTRA SEDE PRINCIPALE</p>
          <span>&nbsp;&nbsp;{address}</span>
        </div>
        <div>
          <PhoneIcon className={styles.contactsIcon} />
          <p>NUMERO DI TELEFONO</p>
          <span>&nbsp;&nbsp;{phoneNumber}</span>
        </div>
        <div>
          <EmailIcon className={styles.contactsIcon} />
          <p>EMAIL</p>
          <span>&nbsp;&nbsp;{email}</span>
        </div>
        <div>
          <div className={styles.socialContainer}>
            <SocialIcon url="https://www.facebook.com/people/Pil-Art-ASD/61551065710690/" target="_blank" />
            <SocialIcon url="https://www.instagram.com/pil_art_danza/" target="_blank" />
          </div>
          <p>SOCIALS</p>
        </div>
      </section>
      <section className={styles.formAndMapsSection}>
        <div>
          <h2>CONTATTACI ORA SU WHATSAPP!</h2>
          <br />

          <form ref={form} onSubmit={sendEmail} className={styles.contactsForm} action="#">
            <textarea name="message" rows="15" cols="100" placeholder="Scrivi il tuo messaggio" required />
            <button type="submit" value="send">
              Invia
            </button>
          </form>
        </div>
        <div>
          <h2>SIAMO QUI!</h2>
          <p>SELEZIONA UNA PALESTRA:</p>

          <select name="locations" onChange={({ target }) => setSelectedLocation(target.value)}>
            <option value="gerole">Stezzano - Gerole</option>
            <option value="impianti_sportivi">Stezzano - Impanti Sportivi</option>
            <option value="caroli">Stezzano - Caroli</option>
            <option value="don_minzoni">Stezzano - Don Minzoni</option>
            <option value="osio_centro_giovanile">Osio Sotto - Centro Giovanile</option>
            <option value="osio_comunale">Osio Sotto - Comunale</option>
          </select>

          <br />
          <br />

          <GoogleMap location={selectedLocation} />
        </div>
      </section>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Contacts;
