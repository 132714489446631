import * as styles from './google-map.module.scss';

import React from 'react';

const locations = {
  gerole:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5577.914624585159!2d9.645373000000001!3d45.651679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478151de2546a49b%3A0xfd8ab66ad01e1c29!2sVia%20Gerole%2C%2029%2C%2024040%20Stezzano%20BG%2C%20Italy!5e0!3m2!1sen!2suk!4v1637505448077!5m2!1sen!2suk',
  impianti_sportivi:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5578.4842694695!2d9.648691!3d45.645959!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x704cf4d1416b2c22!2sImpianti%20Sportivi%20Stezzano!5e0!3m2!1sen!2suk!4v1637507089375!5m2!1sen!2suk',
  caroli:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5578.329216045596!2d9.652008!3d45.647516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe14cf977eb9f6e9b!2sPrimary%20School%20C.Caroli!5e0!3m2!1sen!2suk!4v1637507187381!5m2!1sen!2suk',
  don_minzoni:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5577.960238058737!2d9.645098!3d45.651221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478151de26901aa3%3A0xd2f77e255c8a3b64!2sVia%20Paglia%2C%205%2C%2024040%20Stezzano%20BG%2C%20Italy!5e0!3m2!1sen!2suk!4v1637507213276!5m2!1sen!2suk',
  osio_centro_giovanile:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2790.464708970871!2d9.599588815813563!3d45.62140007910305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47814dd0513c2cbf%3A0xa0a8b108a8454c8b!2sPadel%20Prime%20Osio!5e0!3m2!1sen!2suk!4v1660681541509!5m2!1sen!2suk',
  osio_comunale:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5581.159163448991!2d9.591791000000002!3d45.619092!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47814dcbf0f77627%3A0xf5f4771125ddeb!2sVia%20Torquato%20Tasso%2C%202%2C%2024046%20Osio%20Sotto%20BG%2C%20Italy!5e0!3m2!1sen!2suk!4v1637507343346!5m2!1sen!2suk',
};

const GoogleMap = ({ location }) => {
  const currentLocation = locations[location];

  return (
    <div className={styles.wrapper}>
      <iframe
        title="Google Maps"
        width="100%"
        height="300"
        id="gmap_canvas"
        loading="lazy"
        allowFullScreen
        src={currentLocation === '' ? locations['gerole'] : currentLocation}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      />
    </div>
  );
};

export default GoogleMap;
